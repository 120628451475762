import { axiosConfig } from './axios-config.js'
export const device = {
  // 取得裝置清單
  get: (id, token) => axiosConfig.get(`/api/device/${id || ''}`, token),

  // 更新裝置資訊
  update: (id, data, token) => axiosConfig.patch(`/api/device/${id}`, data, token),

  // 錯誤回報
  errorReport: (id, data, token) => axiosConfig.post(`/api/device/${id}/report`, data, token),

  // 重啟裝置
  reboot: (id, token) => axiosConfig.post(`/api/device/${id}/reboot`, '', token)
}
