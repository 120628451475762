import { axiosConfig } from './axios-config.js'

export const exportReport = {
  // 取得單一裝置通知報表
  singleNotifyReport: (id, data, token) =>
    axiosConfig.post(`/api/report/resident/${id}/export`, data, token),
  // 取得全部裝置通知報表
  allNotifyReport: (data, token) =>
    axiosConfig.post('/api/report/resident/export', data, token),
  // 匯出通知報表
  getExportFile: (taskId, token) =>
    axiosConfig.get(`/api/report/resident/report/${taskId}`, token)
}
