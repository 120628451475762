<template>
  <div class="device-resident">
    <HeaderMenu />
    <div class="container-fluid py-4">
      <div
        class="
          d-flex
          flex-column flex-sm-row
          align-items-center
          justify-content-between
        "
      >
        <div class="links-container">
          <a href="javascript:;" @click="handlerPageMode('device')">
            <h1 class="h5 fw-medium mb-3">{{ $t('__residentDevice') }}</h1>
          </a>
          <a href="javascript:;" @click="handlerPageMode('chiline')">
            <h1 class="h5 fw-medium mb-3">{{ $t('__chilineDevice') }}</h1>
          </a>
        </div>
        <form @submit.prevent="searchDevice" v-if="pageMode === 'device'">
          <div class="input-group mb-3 rounded-pill">
            <input
              v-model="search"
              type="text"
              class="form-control rounded-pill"
              :placeholder="$t('__searchBedNumber')"
              aria-label="search"
              aria-describedby="search"
            />
            <button
              type="submit"
              class="btn btn-link px-2 text-dark"
              id="search"
            >
              <i class="bi bi-search"></i>
            </button>
            <button
              v-if="filter"
              type="button"
              class="btn btn-primary px-3 ms-1 rounded-pill"
              id="clearSearch"
              @click="clearSearch"
            >
              {{ $t('__clearSearch') }}
            </button>
          </div>
        </form>

        <form @submit.prevent="searchDevice" v-if="pageMode === 'chiline'">
          <div class="input-group mb-3 rounded-pill">
            <input
              v-model="search"
              type="text"
              class="form-control rounded-pill"
              :placeholder="$t('__searchBedNumber')"
              aria-label="search"
              aria-describedby="search"
            />
            <button
              type="submit"
              class="btn btn-link px-2 text-dark"
              id="search"
            >
              <i class="bi bi-search"></i>
            </button>
            <button
              v-if="filter"
              type="button"
              class="btn btn-primary px-3 ms-1 rounded-pill"
              id="clearSearch"
              @click="clearSearch"
            >
              {{ $t('__clearSearch') }}
            </button>
          </div>
        </form>
      </div>

      <div class="card" v-if="pageMode === 'device'">
        <div class="card-body" v-if="networks && items">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            striped
            borderless
            no-provider-filtering
            head-variant="light"
            no-sort-reset
            :empty-text="$t('__notData')"
            :empty-filtered-text="$t('__notFindDevice')"
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>
            <template v-slot:cell(bed_number)="data">
              <span
                v-if="data.item.bed_number"
              >
                {{ data.item.bed_number }}
              </span>
              <span v-else>
                {{
                  'N/A'
                }}
              </span>
            </template>
            <template v-slot:cell(resident_name)="data">
              <span
                v-if="data.item.resident"
              >
                {{ data.item.resident.name }}
              </span>
              <span v-else>
                {{
                  'N/A'
                }}
              </span>
            </template>
            <template v-slot:cell(resident_note)="data">
              <span
                v-if="data.item.resident"
              >
                {{ data.item.resident.note }}
              </span>
              <span v-else>
                {{
                  ''
                }}
              </span>
            </template>
            <template v-slot:cell(network_id)="data">
              {{ getNetworkName(data.item.network_id) }}
            </template>
            <template v-slot:cell(connect_status)="data">
              <i
                :class="
                  data.item.state && data.item.state.connect_status
                    ? 'text-success'
                    : 'text-danger'
                "
                class="bi bi-circle-fill"
              ></i>
              <span
                v-if="
                  data.item.state &&
                    data.item.state.connect_status &&
                    data.item.state.lost_connect < 20
                "
              >
                {{ $t('__online') }}
              </span>
              <span
                v-else-if="
                  data.item.state &&
                    data.item.state.connect_status &&
                    data.item.state.lost_connect >= 20
                "
              >
                {{ $t('__connecting') }}
              </span>
              <span v-else>
                {{ $t('__offLine') }}
              </span>
            </template>
            <template v-slot:cell(version)="data">
              {{ data.item.version ? data.item.version : 'N/A' }}
            </template>
            <template v-slot:cell(status)="data">
              {{ data.item.status ? $t('__enable') : $t('__disable') }}
            </template>
            <template v-slot:cell(ended_at)="data">
              {{
                data.item.ended_at === null
                  ? ''
                  : $getTimeZoneDate(
                      data.item.ended_at_epoch,
                      timezone,
                      'YYYY-MM-DD'
                    )
              }}
            </template>
            <template v-slot:cell(active)="data">
              <div class="d-flex" v-if="data.item.status">
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="userInfo && userInfo.isMultiAgency !== true ? $t('__edit') : $t('__noPermission')"
                  class="d-inline-block"
                >
                  <button
                    @click="showEditDeviceModal(data.item.id)"
                    type="button"
                    class="btn btn-link px-1 text-dark"
                    :disabled="userInfo.isMultiAgency"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('__edit')"
                  >
                    <i class="bi bi-pencil mx-1"></i>
                  </button>
                </span>

                <!-- <button
                  v-if="data.item.resident"
                  @click="showDeviceErrorReportModal(data.item.id)"
                  type="button"
                  href=""
                  class="btn btn-link px-1 text-dark"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="$t('__errorReport')"
                >
                  <i class="bi bi-chat-text mx-1"></i>
                </button> -->
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <div class="card" v-if="pageMode === 'chiline'">
        <div class="card-body" v-if="items">
          <b-table
            :responsive="$store.state.windowWidth > $store.state.breakpoints.sm"
            :stacked="$store.state.windowWidth <= $store.state.breakpoints.sm"
            striped
            borderless
            no-provider-filtering
            head-variant="light"
            no-sort-reset
            :empty-text="$t('__notData')"
            :empty-filtered-text="$t('__notFindDevice')"
            :items="processedChilineItems"
            :fields="chilineFields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="chilineFilterOn"
            @filtered="onFiltered"
          >
            <template v-slot:head()="scope">
              <div class="text-nowrap">
                {{ scope.label }}
              </div>
            </template>

            <template v-slot:cell(type)="data">
              <span v-if="data.item.type === 'oxygen'">
                {{ $t('__chilineTypeOxygen') }}
              </span>

              <span v-else-if="data.item.type === 'temperature'">
                {{ $t('__chilineTypeTemperature') }}
              </span>

              <span v-else-if="data.item.type === 'pressure'">
                {{ $t('__chilineDevicePressure') }}
              </span>

              <span v-else>
                {{ $t('__chilineTypeNull') }}
              </span>
            </template>

            <template v-slot:cell(serial_id)="data">
              <span v-if="data.item.device && data.item.device.resident">
                {{ data.item.serial_id }} // {{ data.item.device.resident.name }}
              </span>

              <span v-else>
                {{ data.item.serial_id }}
              </span>
            </template>

            <template v-slot:cell(active)="data">
              <div class="d-flex">
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="userInfo && userInfo.isMultiAgency !== true ? $t('__edit') : $t('__noPermission')"
                  class="d-inline-block"
                >
                  <button
                    @click="showEditChilineDeviceModal(data.item.id)"
                    type="button"
                    class="btn btn-link px-1 text-dark"
                    :disabled="userInfo.isMultiAgency"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    :title="$t('__edit')"
                  >
                    <i class="bi bi-pencil mx-1"></i>
                  </button>
                </span>
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <div class="row align-items-center mt-3" v-if="totalRows">
        <div class="col-sm col-12 text-right text-sm-left">
          {{
            $t('__paginationInfo', {
              startNub: currentPage == 1 ? 1 : perPage * (currentPage - 1) + 1,
              entNub:
                currentPage == 1
                  ? totalRows > perPage
                    ? perPage
                    : totalRows
                  : perPage * (currentPage - 1) + perPage > totalRows
                  ? totalRows
                  : perPage * (currentPage - 1) + perPage,
              sum: totalRows
            })
          }}
        </div>
        <div class="col-sm col-12">
          <b-pagination
            v-if="totalRows > perPage"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-sm-0 mb-3 mt-sm-0 mt-3"
          ></b-pagination>
        </div>
      </div>
    </div>

    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="editDevice"
      ref="editDeviceModal"
    >
      <div v-if="editDeviceModalData" class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitDeviceForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium text-center">
                <span class="d-block">{{ modalTitle.serial_id }}</span>
                <span class="d-block">{{ $t('__editDevice') }}</span>
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="mb-3">
                  <label for="bed_number" class="form-label fw-medium">{{
                    $t('__bedNo')
                  }}</label>
                  <input
                    v-model="editDeviceForm.bed_number"
                    type="text"
                    class="form-control"
                    id="bed_number"
                  />
                  <div
                    class="invalid-feedback"
                    v-if="validate && validate.msg.bed_number"
                  >
                    <div
                      v-for="(msg, index) in validate.msg.bed_number"
                      :key="'bed_number' + index"
                    >
                      {{ msg }}
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="resident_id" class="form-label fw-medium">{{
                    $t('__selectResident')
                  }}</label>
                  <select
                    v-model="editDeviceForm.resident_id"
                    class="form-select"
                    :class="{
                      'is-invalid': validate && validate.msg.resident_id
                    }"
                    id="resident_id"
                  >
                    <option value="">N/A</option>
                    <option
                      v-for="resident in filteredResidents"
                      :key="'resident' + resident.id"
                      :value="resident.id"
                    >
                      {{ resident.name + ':' + resident.id_number }}
                    </option>
                  </select>
                  <div
                    class="invalid-feedback"
                    v-if="validate && validate.msg.resident_id"
                  >
                    <div
                      v-for="(msg, index) in validate.msg.resident_id"
                      :key="'resident_id' + index"
                    >
                      {{ msg }}
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label for="network_id" class="form-label fw-medium">{{
                    $t('__selectNetwork')
                  }}</label>
                  <select
                    v-model="editDeviceForm.network_id"
                    class="form-select"
                    :class="{
                      'is-invalid': validate && validate.msg.network_id
                    }"
                    id="network_id"
                  >
                    <option value="">N/A</option>
                    <option
                      v-for="network in networks"
                      :key="'network' + network.id"
                      :value="network.id"
                    >
                      {{ network.name }}
                    </option>
                  </select>
                  <div
                    class="invalid-feedback"
                    v-if="validate && validate.msg.network_id"
                  >
                    <div
                      v-for="(msg, index) in validate.msg.network_id"
                      :key="'network_id' + index"
                    >
                      {{ msg }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-primary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t('__cancel') }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t('__save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="editChilineDevice"
      ref="editChilineDeviceModal"
    >
      <div v-if="editChilineDeviceModalData" class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitChilineDeviceForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium text-center">
                <span class="d-block">{{ editChilineDeviceModalData.Idno }}</span>
                <span class="d-block">{{ $t('__editDevice') }}</span>
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="mb-3">
                  <label for="resident_id" class="form-label fw-medium">{{
                      $t('__selectDevice')
                    }}</label>
                  <select
                    v-model="editChilineDeviceForm.serial_id"
                    class="form-select"
                    :class="{
                      'is-invalid': validate && validate.msg.serial_id
                    }"
                    id="serial_id"
                  >
                    <option value="">N/A</option>
                    <option
                      v-for="item in items"
                      :key="'item' + item.id"
                      :value="item.serial_id"
                      :disabled="isDisabled(item)"
                    >
                      {{ item.serial_id }} <span v-if="item.resident"> // {{ item.resident.name}}</span>
                    </option>
                  </select>
                  <div
                    class="invalid-feedback"
                    v-if="validate && validate.msg.serial_id"
                  >
                    <div
                      v-for="(msg, index) in validate.msg.serial_id"
                      :key="'serial_id' + index"
                    >
                      {{ msg }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-primary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t('__cancel') }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t('__save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="errorReport"
      ref="errorReportModal"
    >
      <div v-if="modalTitle" class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitErrorReportForm">
            <div class="modal-header">
              <h5 class="modal-title fw-medium text-center">
                <span class="d-block">{{ modalTitle.serial_id }}</span>
                <span class="d-block">{{ $t('__errorReport') }}</span>
              </h5>
              <button
                type="button"
                class="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label class="form-label fw-medium">{{
                  $t('__errorType')
                }}</label>
                <select
                  v-model="errorReportModalData.type"
                  class="form-select"
                  required
                >
                  <option value="" selected>
                    {{ $t('__openThisSelectMenu') }}
                  </option>
                  <option value="1">{{ $t('__RR') }}</option>
                  <option value="2">{{ $t('__SIT') }}</option>
                  <option value="3">{{ $t('__LYB') }}</option>
                  <option value="4">{{ $t('__LVB') }}</option>
                  <option value="5">{{ $t('__restless') }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label fw-medium">{{
                  $t('__wrongTime')
                }}</label>
                <VueCtkDateTimePicker
                  v-model="errorReportTime"
                  :label="$t('__wrongTime')"
                  :max-date="
                    $getTimeZoneDate(new Date(), timezone, 'YYYY-MM-DD HH:mm')
                  "
                  format="YYYY-MM-DDTHH:mm:ss"
                  formatted="YYYY-MM-DD HH:mm"
                  color="#2CBDC0"
                  button-color="#2CBDC0"
                  :no-button-now="true"
                />
              </div>
              <div class="mb-3">
                <label class="form-label fw-medium">{{
                  $t('__description')
                }}</label>
                <textarea
                  v-model="errorReportModalData.description"
                  class="form-control"
                  id=""
                  rows="3"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-primary rounded-pill"
                data-bs-dismiss="modal"
              >
                {{ $t('__cancel') }}
              </button>
              <button type="submit" class="btn btn-primary rounded-pill">
                {{ $t('__save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      class="modal fade cms-style"
      tabindex="-1"
      id="reboot"
      ref="rebootModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-medium text-center">
              <span class="d-block">{{ modalTitle.serial_id }}</span>
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-center">
            {{ $t('__rebootInfo') }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-primary rounded-pill"
              data-bs-dismiss="modal"
            >
              {{ $t('__cancel') }}
            </button>
            <button
              type="button"
              @click="submitReboot"
              class="btn btn-primary rounded-pill"
            >
              {{ $t('__rebootNow') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderMenu from '@/components/layout/HeaderMenu.vue'
import { Tooltip, Modal } from 'bootstrap'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import i18n from '@/lang/lang.js'
import { device } from '@/http/api/device.js'
import { chiline } from '@/http/api/chiline.js'
import { resident } from '@/http/api/resident.js'
import { network } from '@/http/api/network.js'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { exportReport } from '@/http/api/export.js'

export default {
  name: 'DeviceResident',
  components: { HeaderMenu, VueCtkDateTimePicker },
  data () {
    return {
      show_password: false,
      step: 1,
      fields: [
        {
          key: 'serial_id',
          label: i18n.t('__deviceId'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'bed_number',
          label: i18n.t('__bedNo'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'resident_name',
          label: i18n.t('__residentName'),
          class: 'align-middle',
          thStyle: 'min-width:80px',
          sortable: true
        },
        {
          key: 'resident_note',
          label: i18n.t('__note'),
          class: 'align-middle'
        },
        {
          key: 'network_id',
          label: i18n.t('__networkName'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'connect_status',
          label: i18n.t('__networkConnectionStatus'),
          class: 'align-middle',
          sortable: false
        },
        {
          key: 'version',
          label: i18n.t('__version'),
          class: 'align-middle',
          thStyle: 'max-width:100px',
          sortable: true
        },
        {
          key: 'status',
          label: i18n.t('__status'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'ended_at',
          label: i18n.t('__endedAt'),
          class: 'align-middle',
          thStyle: 'min-width:100px',
          sortable: true
        },
        {
          key: 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle'
        }
      ],
      chilineFields: [
        {
          key: 'type',
          label: i18n.t('__chilineType'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'serial_id',
          label: i18n.t('__deviceAndResident'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'Idno',
          label: i18n.t('__deviceId'),
          class: 'align-middle',
          sortable: true
        },
        {
          key: 'active',
          label: i18n.t('__active'),
          tdClass: 'align-middle',
          class: 'align-middle'
        }
      ],
      filter: null,
      filterOn: ['serial_id', 'bed_number', 'resident'],
      chilineFilterOn: ['serial_id', 'Idno', 'name'],
      items: null,
      chilineItems: null,
      processedChilineItems: [],
      residents: null,
      networks: null,
      search: null,
      editDeviceModalData: null,
      editChilineDeviceModalData: null,
      errorReportTime: null,
      errorReportModalData: {
        type: 1,
        trouble_time: null,
        description: null
      },
      modalTitle: {
        id: null,
        serial_id: null,
        bed_number: null,
        resident_name: null
      },
      exportTaskId: null,
      bootstrap_tooltip: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      editDeviceId: null,
      editDeviceForm: {
        bed_number: null,
        resident_id: '',
        network_id: ''
      },
      editChilineDeviceId: null,
      editChilineDeviceForm: {
        serial_id: null,
        type: null
      },
      bootstrap_modal: null,
      validate: null,
      oldWifi: null,
      pageMode: 'device'
    }
  },
  computed: {
    ...mapState(['token', 'userInfo', 'timezone']),
    ...mapGetters(['timeZoneOffset']),
    errorReportTimeIsTaiwanTimeZone () {
      const vm = this
      return vm.$getTimeZoneDate(
        new Date(vm.errorReportTime + vm.timeZoneOffset),
        'Asia/Taipei',
        'YYYY-MM-DD HH:mm'
      )
    },
    filteredResidents () {
      return this.residents.filter(resident => {
        return resident.device === null || resident.id === this.editDeviceForm.resident_id
      })
    }
  },
  watch: {
    userInfo () {
      this.getNetwork()
      this.getResident()
      this.getDevice()
    },
    items (data) {
      this.totalRows = data.length
      this.initBootstrapTooltip()
    },
    chilineItems (data) {
      this.totalRows = data.length
      this.initBootstrapTooltip()
      this.processedChilineItems = data.map(item => ({
        ...item,
        name: item.device && item.device.resident ? item.device.resident.name : ''
      }))
    },
    item (data) {
    },
    editDeviceForm: {
      handler: function () {
        this.validate = null
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations([
      'LoadingText',
      'Loading',
      'Loaded',
      'AlertsInfo',
      'Alerted'
    ]),
    showedPassword () {
      this.show_password = !this.show_password
    },
    getDevice (pageMode = 'device') {
      this.pageMode = pageMode
      const vm = this
      vm.Loading()
      device.get('', vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          const data = res.data.data
          vm.items = data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    },
    getChiline () {
      const vm = this
      vm.pageMode = 'chiline'
      vm.Loading()
      chiline.get('', vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          const data = res.data.data
          vm.chilineItems = data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
        vm.Loaded()
      })
    },
    getResident () {
      const vm = this
      resident.get('', vm.token).then((res) => {
        if (res.status <= 201) {
          const data = res.data.data
          vm.residents = data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    getNetwork () {
      const vm = this
      network.get('', vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          const data = res.data.data
          vm.networks = data
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    getNetworkName (id) {
      const vm = this
      var name = 'N/A'
      vm.networks.forEach(network => {
        if (network.id === id) {
          name = network.name
          return false
        }
      })
      return name
    },
    showEditDeviceModal (id) {
      const vm = this
      vm.items.forEach(item => {
        if (item.id === id) {
          vm.editDeviceModalData = item
          vm.editDeviceId = id
          vm.editDeviceForm.bed_number = item.bed_number
          if (item.resident) {
            vm.editDeviceForm.resident_id = item.resident.id || ''
          } else {
            vm.editDeviceForm.resident_id = ''
          }
          vm.editDeviceForm.network_id = item.network_id || ''
          vm.modalTitle.serial_id = item.serial_id

          vm.oldWifi = item.network_id
        }
      })
      vm.$nextTick(function () {
        vm.bootstrap_modal = new Modal(vm.$refs.editDeviceModal)
        vm.bootstrap_modal.show()
      })
    },
    showEditChilineDeviceModal (id) {
      const vm = this
      vm.chilineItems.forEach(item => {
        if (item.id === id) {
          vm.editChilineDeviceModalData = item
          vm.editChilineDeviceId = item.id
          vm.editChilineDeviceForm.type = item.type
          vm.editChilineDeviceForm.serial_id = item.serial_id
        }
      })
      vm.$nextTick(function () {
        vm.bootstrap_modal = new Modal(vm.$refs.editChilineDeviceModal)
        vm.bootstrap_modal.show()
      })
    },
    submitDeviceForm () {
      const vm = this
      vm.Loading()
      device
        .update(vm.editDeviceId, vm.editDeviceForm, vm.token)
        .then(res => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.getDevice()
            vm.getResident()
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__editSuccess')
            })
            vm.Alerted()
            vm.bootstrap_modal.hide()
            if (
              (vm.editDeviceForm.network_id === '') ||
              (vm.editDeviceForm.network_id === null) // 如果設定成Ｎ/A則不要跳出reboot
            ) {
              vm.Loaded()
              return
            }
            if (vm.oldWifi !== vm.editDeviceForm.network_id) {
              vm.rebootModal()
            }
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
            vm.validate = res.data.validate
          }
          vm.Loaded()
        })
    },
    submitChilineDeviceForm () {
      const vm = this
      vm.Loading()
      chiline
        .update(vm.editChilineDeviceId, vm.editChilineDeviceForm, vm.token)
        .then(res => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.getChiline()
            vm.getDevice('chiline')
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__editSuccess')
            })
            vm.Alerted()
            vm.bootstrap_modal.hide()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
            vm.validate = res.data.validate
          }
          vm.Loaded()
        })
    },
    submitErrorReportForm () {
      const vm = this
      vm.Loading()
      vm.errorReportModalData.trouble_time = vm.errorReportTimeIsTaiwanTimeZone
      device
        .errorReport(vm.editDeviceId, vm.errorReportModalData, vm.token)
        .then(res => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.getDevice()
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__submitSuccess')
            })
            vm.Alerted()
            vm.bootstrap_modal.hide()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
            vm.validate = res.data.validate
          }
          vm.Loaded()
        })
    },
    submitReboot () {
      const vm = this
      vm.Loading()
      device.reboot(vm.editDeviceId, vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.AlertsInfo({
            state: 'success',
            title: i18n.t('__success'),
            info: i18n.t('__rebootSuccess')
          })
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__rebootError'),
            info: res.data.errMsg.toString()
          })
        }
        vm.Loaded()
        vm.Alerted()
        vm.bootstrap_modal.hide()
      })
    },
    rebootModal () {
      const vm = this
      vm.bootstrap_modal = new Modal(vm.$refs.rebootModal)
      vm.bootstrap_modal.show()
    },
    showDeviceErrorReportModal (id) {
      const vm = this
      vm.errorReportModalData.type = 1
      vm.errorReportModalData.trouble_time = null
      vm.errorReportModalData.description = null
      vm.editDeviceId = id
      vm.items.forEach(item => {
        if (item.id === id) {
          vm.modalTitle.serial_id = item.serial_id
        }
      })
      vm.$nextTick(function () {
        vm.bootstrap_modal = new Modal(vm.$refs.errorReportModal)
        vm.bootstrap_modal.show()
      })
    },
    exportData () {
      const vm = this
      vm.Loading()
      exportReport
        .singleNotifyReport(vm.modalTitle.id, '', vm.token)
        .then(res => {
          if (res.status <= 201 && res.data.status === 'success') {
            vm.exportTaskId = res.data.taskId
            vm.exportFile()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg
            })
            vm.Alerted()
          }
        })
    },
    exportFile () {
      const vm = this
      exportReport.getExportFile(vm.exportTaskId, vm.token).then(res => {
        if (res.status <= 201) {
          switch (res.data.status) {
            case 'failed':
              vm.AlertsInfo({
                state: 'error',
                title: i18n.t('__error'),
                info: res.data.errMsg
              })
              vm.Alerted()
              vm.Loaded()
              break
            case 'running':
              setTimeout(() => {
                vm.exportFile()
              }, 3000)
              break
            case 'success':
              window.open(
                /https/.test(res.data.path)
                  ? res.data.path
                  : window.location.origin + res.data.path,
                '_blank'
              )
              vm.Loaded()
              vm.step = 2
              break
          }
        } else {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errMsg
          })
          vm.Alerted()
        }
      })
    },
    initBootstrapTooltip () {
      const vm = this
      vm.$nextTick(function () {
        const delay = { show: 200, hide: 200 }
        vm.bootstrap_tooltip = [].slice
          .call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
          .map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl, {
              trigger: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? 'manual' : 'hover',
              delay: delay
            })
          })
      })
    },
    searchDevice () {
      this.filter = this.search
    },
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    clearSearch () {
      this.filter = null
      this.search = null
    },
    handlerPageMode (mode) {
      if (mode === 'chiline') {
        this.getChiline()
      }

      if (mode === 'device') {
        this.getDevice()
      }

      this.pageMode = mode
    },
    isDisabled (item) {
      return item.chiline_oxygen_user != null && item.chiline_temperature_user != null && item.chiline_pressure_user != null
    }
  },
  created () {},
  mounted () {
    if (this.userInfo) {
      this.getNetwork()
      this.getResident()
      this.getDevice()
    }
  }
}
</script>

<style lang="scss">
#notify {
  .modal-content {
    background-color: #f3f3f3;
  }
}
.custom-tab-check {
  .form-check {
    position: relative;
    width: 105px;
    height: 34px;
    margin-bottom: 20px;
    &-input {
      opacity: 0;
      &:checked + .form-check-label {
        background-color: $primary;
        color: #fff;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 90%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 5px 0 5px;
          border-color: $primary transparent transparent transparent;
        }
      }
    }
    &-label {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding: 5px 0;
      text-align: center;
      background-color: #fff;
      border-radius: 5px 0 0 5px;
    }
    &:last-child {
      .form-check-label {
        border-radius: 0 5px 5px 0;
      }
    }
  }
}

.slider-connect {
  background: $primary;
}
.slider-tooltip {
  border: 1px solid $dark;
  background: $dark;
}
.links-container {
  display: flex;
  align-items: center;
  justify-content: space-around;

  a {
    margin-right: 10px;
  }
}
</style>
