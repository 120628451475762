import { axiosConfig } from './axios-config.js'
export const network = {
  // 取得網路清單
  get: (id, token) => axiosConfig.get(`/api/network/${id || ''}`, token),

  // 新增網路
  create: (data, token) => axiosConfig.post('/api/network', data, token),

  // 更新網路
  update: (id, data, token) => axiosConfig.patch(`/api/network/${id}`, data, token),

  // 刪除網路
  delete: (id, token) => axiosConfig.delete(`/api/network/${id}`, token)
}
